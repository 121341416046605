import React from "react";

import bottle from "../../images/hydrocolagina/HydroGraviola.png";
import Form from "./DynamicForm";

export default function CTASection({
	modalRef,
	team_id,
	destination = "almalu",
	quantity_available,
	short,
	onStored,
	sent,
}) {
	return (
		<div className="flex flex-col items-center md:flex-row gap-x-4">
			<div className="w-full space-y-5 md:w-3/5">
				{!short && (
					<>
						<p className="font-medium text-orange-400 uppercase">
							HydroColagina XXI - Cuida tu salud de adentro hacia afuera
						</p>
						<h2 className="text-xl sm:text-2xl md:text-4xl font-extrabold leading-none text-orange-500 uppercase">
							Mejora tu piel, cabello, uñas, alivia dolor en articulaciones
						</h2>
						<p className="text-xl text-gray-600 hidden md:inline">
							HydroColagina XXI + Guanabana es el{" "}
							<span className="font-semibold">
								método efectivo para cuidar tu salud de adentro hacia afuera
							</span>
							, con componentes 100% naturales.
						</p>
						<p className="text-gray-800 text-lg">
							Realiza tu pedido hoy y recibe la{" "}
							<span className="font-bold">oferta de tiempo limitado</span> que
							tenemos para ti.
						</p>
					</>
				)}
				<div className="flex flex-nowrap justify-center">
					<img className="h-48 md:h-56" src={bottle} alt="Diabetamina" />
					<div className="flex flex-col items-center">
						<div className="rounded-full bg-gradient-to-b from-yellow-700 to-yellow-400 md:text-xl text-white w-24 h-24 md:w-32 md:h-32 flex flex-col justify-center items-center">
							<span className="">DESCUENTO</span>
							<span className="font-bold text-3xl md:text-4xl">50%</span>
						</div>
						<p className="text-center md:text-lg font-semibold">
							Precio antiguo: <br /> <span className="line-through">49800</span>{" "}
							<br /> <span className="line-through">30 cápsulas</span>{" "}
						</p>
						<p className="text-center text-lg md:text-xl text-red-600 font-semibold">
							Precio con descuento: <br />{" "}
							<span className="text-xl md:text-3xl font-bold">₡24900</span>{" "}
							<br /> <span className="text-2xl font-bold">60 cápsulas</span>{" "}
						</p>
					</div>
				</div>
				<p className="text-red-600 font-semibold text-2xl text-center">
					¡Dese prisa, sólo quedan{" "}
					<span className="bg-orange-300 py-1 px-3 rounded border border-orange-600 text-black">
						{quantity_available}
					</span>{" "}
					unidades disponibles!
				</p>
			</div>

			<div className="w-full mt-8 md:mt-0 md:w-2/5">
				<Form
					onStored={onStored}
					sent={sent}
					product="HydroColagina XXI + Guanabana"
					team_id={team_id}
					destination={destination}
					type="full"
				/>
			</div>
		</div>
	);
}
