import React, { useState } from "react";

export default function Form({
	children,
	product,
	team_id,
	destination = "almalu",
	onStored,
	type,
	quantity_available,
	sent,
}) {
	const defaultValues = {
		name: "",
		phone: "",
		comments: "Deseo info del producto",
		main: "",
	};
	const [inputs, setInputs] = useState(defaultValues);
	const [errors, setErrors] = useState(defaultValues);
	const [loading, setLoading] = useState("");

	let uri = "";
	if (destination === "almalu")
		uri = "https://crm.almalunatural.com/api/campaigns/store-lead";
	else if (destination === "vibe")
		uri = "https://vibecosmeticos.net/api/campaigns/store-lead";

	function handleChange(e, type) {
		const val = e.target.value;
		if (typeof val !== "string") {
			return false;
		}

		setInputs({ ...inputs, [type]: val });
	}

	function handleSubmit(e) {
		e.preventDefault();

		setErrors(defaultValues);

		if (inputs.name.length < 3)
			return setErrors({
				...defaultValues,
				name: "Por favor ingresa tu nombre",
			});
		if (inputs.phone < 100000)
			return setErrors({
				...defaultValues,
				phone: "Por favor ingresa un número de teléfono válido",
			});

		setLoading("cursor-progress bg-navy-300 hover:bg-navy-300");

		// var data = {product: product, team_id: team_id, client_name: inputs.name, client_phone: inputs.phone}
		let data = new FormData();
		data.append("product", product);
		data.append("team_id", team_id);
		data.append("client_name", inputs.name);
		data.append("client_phone", inputs.phone);
		data.append("comments", inputs.comments);
		fetch(uri, {
			method: "POST",
			body: data,
		})
			.then(async (response) => {
				if (!response.ok) {
					const validation = await response.json();
					console.log(validation.errors);
					setErrors({ ...errors, main: validation.errors });
				} else {
					onStored(e);
					window.dataLayer.push({
						event: "lead_stored",
						product: product,
					});
				}
				setLoading("");
			})
			.catch((error) => {
				console.log(error);
				setLoading("");
			});
	}

	return (
		<>
			{sent !== true ? (
				<form
					className="max-w-5xl bg-white text-black rounded border-[5px] border-dashed"
					onSubmit={handleSubmit}
				>
					{type === "standalone" && (
						<div>
							<div className="bg-gray-200 py-4 mb-2">
								<p className="text-center text-xl text-orange-500 font-semibold">
									21 tipos de colágeno hidrolizado{" "}
									<span className="font-bold">con vitamina C y guanábana</span>{" "}
									para un cuidado integral del cuerpo
								</p>
							</div>
							<div>
								<div className="bg-gray-200 grid grid-cols-2 py-4 mb-2">
									<p className="text-center text-lg font-semibold">
										Precio antiguo: <br />{" "}
										<span className="line-through">49800</span> <br />{" "}
										<span className="line-through">30 cápsulas</span>{" "}
									</p>
									<p className="text-center text-xl text-red-600 font-semibold">
										Precio con descuento: <br />{" "}
										<span className="text-3xl font-bold">₡24900</span> <br />{" "}
										<span className="text-2xl font-bold">60 cápsulas</span>{" "}
									</p>
								</div>
							</div>
						</div>
					)}
					<div className={`px-8 mb-6`}>
						<h3
							className={`mb-6 font-medium text-center pt-2 ${
								type === "standalone" ? "text-lg" : "text-2xl"
							}`}
						>
							Introduzca sus datos en el formulario abajo
						</h3>
						<p className="mt-4 text-gray-600">
							{inputs.name.length ? "Nombre" : ""}
						</p>
						<input
							onChange={(e) => handleChange(e, "name")}
							value={inputs.name}
							className="block w-full px-4 py-3 border-2 border-transparent border-gray-200 rounded-lg focus:ring focus:ring-red-500 focus:outline-none"
							placeholder="Nombre"
						/>
						{errors.name && (
							<span className="text-red-500 px-2 text-sm">{errors.name}</span>
						)}

						<p className="mt-4 text-gray-600">
							{inputs.phone.length ? "Número de teléfono" : ""}
						</p>
						<input
							onChange={(e) => handleChange(e, "phone")}
							value={inputs.phone}
							type="number"
							className="block w-full appearance-none px-4 py-3 border-2 border-transparent border-gray-200 rounded-lg focus:ring focus:ring-red-500 focus:outline-none"
							placeholder="Número de teléfono"
						/>
						{errors.phone && (
							<span className="text-red-500 px-2 text-sm">{errors.phone}</span>
						)}

						{/* <p className="mt-4 text-gray-600">¿Cuánto peso deseas bajar?</p>
					<select 
						onChange={(e) => handleChange(e, 'comments')} value={inputs.comments}
						className="block w-full appearance-none px-4 py-3 border-2 border-transparent border-gray-200 rounded-lg focus:ring focus:ring-navy-500 focus:outline-none"
					>
						<option value="Deseo bajar de 1-5 kg">1-5 kilos</option>
						<option value="Deseo bajar de 6-10 kg">6-10 kilos</option>
						<option value="Deseo bajar más de 10 kg">Más de 10 kilos</option>
					</select> */}

						<div className="block">
							{errors.main && (
								<span className="text-red-600 px-2 text-base">
									{errors.main}
								</span>
							)}
							<button
								type="submit"
								className={`w-full px-3 py-3 mt-4 text-lg md:text-xl text-white bg-red-600 hover:bg-red-500 border-[3px] transition-colors duration-300 border-black rounded-full ${loading} `}
							>
								Hacer el pedido{" "}
								<span className="hidden md:block">con el descuento</span>
							</button>
						</div>

						{type === "standalone" && (
							<p className="text-red-600 font-semibold text-lg md:text-xl pt-5 text-center">
								¡Dese prisa, sólo quedan{" "}
								<span className="bg-orange-300 py-1 px-3 rounded border border-orange-600 text-black">
									{quantity_available ?? 5}
								</span>{" "}
								unidades disponibles!
							</p>
						)}
					</div>
				</form>
			) : (
				<div className="w-full space-y-5 max-w-5xl bg-white text-black rounded border-[5px] border-dashed p-5 text-center">
					<h2 className="text-xl sm:text-2xl md:text-3xl font-extrabold leading-none text-red-700 uppercase">
						Datos enviados
					</h2>
					<p className="text-xl text-gray-600">
						Hemos recibido tus datos y nos pondremos en contacto contigo lo más
						pronto posible para darte más información sobre la promoción.
					</p>
				</div>
			)}
		</>
	);
}
